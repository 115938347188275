:root {
    --primary-color-1: #0C62F7;
    --text-primary: #0C62F7;
    --text-error: #C10808;
    --menu-active-text-color: #0C62F7;
    --menu-inactive-text-color: #696E80;
    --menu-small-title: #A3ADC1;
    --page-title: #0F2040;
    --card-bg: #FCFCFC;
    --text-visible: #0F2040;
    --text-invisible: #A3ADC1;
}

@font-face {
    font-family: 'Tickerbit Italic';
    src: local('VisbyCF'), url(../fonts/Tickerbit-italic.otf) format('opentype');
}

@font-face {
    font-family: 'Tickerbit Mono';
    font-weight: 100;
    src: local('VisbyCF'), url(../fonts/Tickerbit-mono.otf) format('opentype');
}

@font-face {
    font-family: 'Tickerbit Regular';
    font-weight: 500;
    src: local('VisbyCF'), url(../fonts/Tickerbit-regular.otf) format('opentype');
}

@font-face {
    font-family: 'Tickerbit MonoItalic';
    font-weight: 600;
    src: local('VisbyCF'), url(../fonts/Tickerpixel-monoitalic.otf) format('opentype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('~assets/fonts/Poppins-Bold.ttf');
    src: url('~assets/fonts/Poppins-Bold.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('~assets/fonts/Poppins-Light.ttf');
    src: url('~assets/fonts/Poppins-Light.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('~assets/fonts/Poppins-Medium.ttf');
    src: url('~assets/fonts/Poppins-Medium.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('~assets/fonts/Poppins-Regular.ttf');
    src: url('~assets/fonts/Poppins-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('~assets/fonts/Poppins-SemiBold.ttf');
    src: url('~assets/fonts/Poppins-SemiBold.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cyber Soulja';
    src: url('~assets/fonts/Cyber Soulja.otf');
    src: url('~assets/fonts/Cyber Soulja.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ShareTechMono';
    src: url('~assets/fonts/ShareTechMono-Regular.ttf');
    src: url('~assets/fonts/ShareTechMono-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proximate-Regular';
    src: url('~assets/fonts/Proximate-Regular.ttf');
    src: url('~assets/fonts/Proximate-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IstokWeb-Bold';
    src: url('~assets/fonts/IstokWeb-Bold.ttf');
    src: url('~assets/fonts/IstokWeb-Bold.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IstokWeb-Regular';
    src: url('~assets/fonts/IstokWeb-Regular.ttf');
    src: url('~assets/fonts/IstokWeb-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Poppins-Regular' !important;
    font-style: normal;
    line-height: 1 !important;
    touch-action: pan-x pan-y;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

.font-8 {
    line-height: 105%;
    font-size: 0.5em;
}

.font-10 {
    font-size: 0.625em;
}

.font-12 {
    font-size: 0.75em;
}

.font-14 {
    font-size: 0.875em;
}

.font-16 {
    font-size: 1em;

    @media screen and (max-width: 667px) {
        font-size: 1em;
    }
}

.font-18 {
    font-size: 1.125em;
}

.font-19 {
    font-size: 1.1875em;
}

.font-20 {
    font-size: 1.25em;
}

.font-22 {
    font-size: 1.375em;
}

.font-24 {
    font-size: 1.5em;
}

.font-25 {
    font-size: 1.2vw;
    margin-top: 1vh;

    @media screen and (max-width: 991px) {
        font-size: 1.5625em;
    }
}

.font-26 {
    font-size: 1.625em;
}

.font-28 {
    font-size: 1.75em;
}

.font-30 {
    font-size: 1.875em;
}

.font-32 {
    font-size: 2em;
}

.font-34 {
    font-size: 2.125em;
}

.font-35 {
    font-size: 2.1875em;
}

.font-40 {
    font-size: 2.5em;
}

.font-100 {
    font-size: 6.25em;
}

.letter-spacing-18px {
    letter-spacing: 1.125em
}

.line-height-145percent {
    line-height: 145.5%;
}

.line-height-100percent {
    line-height: 100.5%;
}

.line-height-120percent {
    line-height: 120%;
}

.text-primary-color {
    color: var(--text-primary);
}

.text-menu-active-text-color {
    color: var(--menu-active-text-color);
}

.text-menu-inactive-text-color {
    color: var(--menu-inactive-text-color);
}

.text-page-title {
    color: var(--page-title);
}

.text-visible {
    color: #0F2040;
}

.text-invisible {
    color: #A3ADC1;
}

a {
    text-decoration: none !important;
}

.min-height-100 {
    min-height: 100vh;
}

.slick-prev {
    z-index: 1;
}

.navbar-quantbridge {
    background: linear-gradient(180deg, #031137 -24.06%, rgba(3, 17, 55, 1) 100%);
}

.gsapSection {
    min-height: 115vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: end;


    @media screen and (max-width: 1200px) {
        display: none;
    }


}

.gsapScrollText {
    color: #00326E;
    font-family: 'Proximate-Regular';
    font-size: 6vw;
    font-style: normal;
    font-weight: 400;
    line-height: 118.945%;
    letter-spacing: 7.2px;
    text-transform: uppercase;

    @media screen and (max-width: 991px) {
        font-size: 50px;
    }

    @media screen and (max-width: 667px) {
        font-size: 35px;
    }

    @media screen and (max-width: 300px) {
        font-size: 25px;
    }
}

.gsapScrollText1 {
    width: auto;
    white-space: nowrap;
    transform: rotate(0deg) translate3d(-100%, 0, 0);
    text-align: center;
}

.gsapScrollText2 {
    width: 100%;
    white-space: nowrap;
    transform: rotate(0deg) translate3d(100%, 0, 0);
    text-align: center;
}

.footer {
    // border: 3px solid #050E20;
    background-image: url(../img/logo/footerBg.png);
    background-size: 100% 100%;
    border-radius: 60px 60px 0 0;
    margin-top: -8vh;
    position: sticky;
    z-index: 1;

    @media screen and (max-width: 991px) and (orientation:landscape) {
        margin-top: -15vh;
    }

    .footerPadding {
        padding: 5vh 0 0 0;

        @media screen and (max-width: 991px) {
            padding: 3rem 0;
        }
    }

    .footerLogoSize {
        width: 50%;
    }

    .footerTitleText {
        color: #FFF;
        font-family: 'IstokWeb-Regular';
        font-size: 1.2vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media screen and (max-width: 991px) {
            font-size: 20px;
        }

        @media screen and (max-width: 667px) {
            font-size: 17px;
        }

        @media screen and (max-width: 300px) {
            font-size: 15px;
        }
    }

    .footerDescText {
        color: rgba(255,255,255,0.5);
        font-family: 'IstokWeb-Regular';
        font-size: 0.9vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media screen and (max-width: 991px) {
            font-size: 17px;
        }

        @media screen and (max-width: 667px) {
            font-size: 14px;
        }

        @media screen and (max-width: 300px) {
            font-size: 13px;
        }
    }

    .socialMediaIcon {
        width: 3vw;
        height: 3vw;
        border-radius: 50%;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media screen and (max-width: 991px) {
            width: 50px;
            height: 50px;
        }

        .socialMediaSize {
            font-size: 1.2vw;
            color: #000;

            @media screen and (max-width: 991px) {
                font-size: 20px;
            }

            @media screen and (max-width: 667px) {
                font-size: 17px;
            }

            @media screen and (max-width: 300px) {
                font-size: 15px;
            }
        }
    }

    .copyrightText {
        color: #FFF;
        text-align: center;
        font-family: 'IstokWeb-Regular';
        font-size: 0.8vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media screen and (max-width: 991px) {
            font-size: 17px;
        }

        @media screen and (max-width: 667px) {
            font-size: 13px;
        }

        @media screen and (max-width: 300px) {
            font-size: 11px;
        }
    }
}

.sectionTitle {
    color: #08D1FF;
    text-align: center;
    font-family: 'Proximate-Regular';
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: 110.445%;
    letter-spacing: 3.6px;
    text-transform: uppercase;

    @media screen and (max-width: 991px) and (orientation:portrait) {
        font-size: 50px;
    }

    @media screen and (max-width: 991px) and (orientation:landscape) {
        font-size: 35px;
    }

    @media screen and (max-width: 667px) {
        font-size: 35px;
    }

    @media screen and (max-width: 300px) {
        font-size: 25px;
    }
}

.sectionDesc {
    color: #FFF;
    text-align: center;
    font-family: 'IstokWeb-Regular';
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 991px) and (orientation:portrait) {
        font-size: 20px;
    }

    @media screen and (max-width: 991px) and (orientation:landscape) {
        font-size: 16px;
    }

    @media screen and (max-width: 667px) {
        font-size: 16px;
    }

    @media screen and (max-width: 300px) {
        font-size: 14px;
    }
}

.headerTitle {
    color: #FFF;
    font-family: 'Proximate-Regular';
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100.945%;
    letter-spacing: 5.4px;
    text-transform: capitalize;
    text-align: left;

    @media screen and (max-width: 991px) and (orientation:portrait) {
        font-size: 50px;
    }

    @media screen and (max-width: 991px) and (orientation:landscape) {
        font-size: 35px;
    }

    @media screen and (max-width: 667px) {
        font-size: 35px;
    }

    @media screen and (max-width: 300px) {
        font-size: 25px;
    }
}

.tradingPlatformSymbol {
    width: 2vw;

    @media screen and (max-width: 991px) and (orientation:portrait) {
        width: 100%;
    }

    @media screen and (max-width: 768px) and (orientation:landscape) {
        width: 40%;
    }
}

.backToTopBtn {
    position: sticky;
    z-index: 999;
    width: 3vw !important;
    height: 3vw !important;

    @media screen and (max-width: 991px) {
        width: 60px !important;
        height: 60px !important;
    }

    @media screen and (max-width: 667px) {
        width: 50px !important;
        height: 50px !important;
    }

    .scrollTopIcon {
        font-size: 1.5vw !important;

        @media screen and (max-width: 991px) {
            font-size: 25px !important;
        }
    }
}

#widget-ticker-tape-container {
    display: none;
}

.headerPadding {
    @media screen and (min-width: 992px) {
        padding: 0 4vw;
    }
}

.btnDiv {
    width: fit-content;
    margin: auto;
    text-align: center;
}

.btnDiv2 {
    width: fit-content;
}