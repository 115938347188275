.contactUs {
    background-color: #031137;

    #contactUsHeader {
        background-image: url(../img/contactUs/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;
        margin-bottom: 5vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
            padding-bottom: 0vh;
        }
    }

    #contactUsForm {
        background-image: url(../img/contactUs/contactFormBg.png);
        background-size: 100% 70%;
        background-position: bottom;
        padding: 25vh 0;
        background-repeat: no-repeat;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }

        .contactFormBg {
            // background: linear-gradient(0deg, #031137 -12.35%, #003570 103.83%);
            // box-shadow: 0px 12px 18px 0px rgba(255, 255, 255, 0.09) inset;
            background-image: url(../img/contactUs/contactForm.png);
            background-size: 100% 100%;
            border-radius: 50px;
            padding: 5vh 0;

            @media screen and (max-width: 991px) {
                padding: 3rem 0;
                background-size: cover;
                background-position: center;
            }
        }

        .contactFormTitle {
            color: #08D1FF;
            text-align: center;
            font-family: 'Proximate-Regular';
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 3.6px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 40px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 30px;
            }

            @media screen and (max-width: 667px) {
                font-size: 30px;
            }

            @media screen and (max-width: 300px) {
                font-size: 25px;
            }
        }

        .contactFormFieldTitle {
            color: #fff;
            text-align: center;
            font-family: 'IstokWeb-Regular';
            font-size: 0.8vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 3.6px;
            text-transform: uppercase;


            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 16px;
                letter-spacing: 1px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 15px;
                letter-spacing: 1px;
            }

            @media screen and (max-width: 667px) {
                font-size: 15px;
                letter-spacing: 1px;
            }

            @media screen and (max-width: 300px) {
                font-size: 13px;
                letter-spacing: 1px;
            }
        }

        .contactForm-input {
            color: #000;
            background-color: #fff;
            border-radius: 0;
            font-size: 0.8vw;
            padding: 2vh;

            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 16px;
                letter-spacing: 1px;
                padding: 1.8vh;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 15px;
                letter-spacing: 1px;
                padding: 1.5vh;
            }

            @media screen and (max-width: 667px) {
                font-size: 15px;
                letter-spacing: 1px;
                padding: 1.5vh;
            }

            @media screen and (max-width: 300px) {
                font-size: 13px;
                letter-spacing: 1px;
                padding: 1.5vh;
            }
        }

        .yourmessage-input {
            border-bottom: 0px !important;
            max-height: 25vh !important;
            background: #EDF7FF !important;
        }

        .columnSpacing {
            margin-bottom: 2vh;
        }

        .form-check-input {
            width: 1vw;
            height: 1vw;
            background-color: transparent;

            @media screen and (max-width: 991px) {
                width: 20px;
                height: 20px;
            }

            @media screen and (max-width: 667px) {
                width: 15px;
                height: 15px;
            }

        }

        .checkboxText {
            margin-left: 1.2vw;
        }

        .submitBtn {
            border-radius: 35px;
            background: var(--Linear, linear-gradient(107deg, #8E5639 4.17%, #765B4C 50.16%, #A59573 101.19%));
            width: fit-content;
            cursor: pointer;

            padding: 0.8vw 7vw;

            @media screen and (max-width: 991px) {
                padding: 1rem 4rem;
            }

            span {
                color: #FFF;
                font-family: 'IstokWeb-Regular';
                font-size: 0.9vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;

                @media screen and (max-width: 991px) {
                    font-size: 15px;
                }
            }
        }

        .errorTextRed {
            color: red;
            font-size: 0.7vw;
        }
    }
}