.ourTeam {
    background-color: #031137;

    #ourTeamHeader {
        background-image: url(../img/ourTeam/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;
        margin-bottom: 20vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
            margin-bottom: 0vh;
        }
    }

    .ourTeamIntro {
        margin: 10vh 0;

        @media screen and (max-width: 991px) {
            margin: 0;
        }
    }

    #ourPhilosophy {
        .ourPhilosophyBox {
            border-radius: 30px;
            background: #050E20;
            position: sticky;
            z-index: 1;
        }

        .philosophyImg {
            border-radius: 30px 0 0 30px;

            @media screen and (max-width: 991px) {
                border-radius: 30px;
            }
        }

        .philosophyDeco {
            position: absolute;
            left: 0;
            top: -40%;
            z-index: 0;
        }
    }

    #customizedApproach {
        padding-top: 20%;

        .customizedApproachBox {
            border-radius: 30px;
            background: #050E20;
            position: relative;
            padding: 2vw;
            z-index: 1;

            @media screen and (max-width: 991px) {
                padding: 3rem
            }

            @media screen and (max-width: 667px) {
                padding: 2rem 0.5rem;
            }
        }

        .customizedApproachImg {
            position: absolute;
            bottom: -10vh;
            right: 0;
            width: 90%;

            @media screen and (max-width: 991px) {
                display: none;
            }
        }

        .customizedApproachDeco {
            position: absolute;
            z-index: 0;
            bottom: -20%;
            right: 0;
        }
    }

    #provenApproach {
        padding: 10vh 0;
        background-image: url(../img/ourTeam/provenApproachBg.png);
        background-size: 100% 100%;
        min-height: 90vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: 60%;
        }

        .marqueeHugeText {
            color: #E9AE49;
            text-align: center;
            font-family: 'Proximate-Regular';
            font-size: 10vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 10px;
            text-transform: uppercase;
            opacity: 0.22;

            @media screen and (max-width: 991px) {
                font-size: 120px;
            }

            @media screen and (max-width: 667px) {
                font-size: 80px;
            }
        }
    }

    #analysisDiv {
        margin-top: -8vh;

        .analysisBox {
            background: #050E20;
            padding: 3vw;

            @media screen and (max-width: 991px) {
                padding: 3rem
            }

            @media screen and (max-width: 667px) {
                padding: 1rem;
            }
        }

        .analysisTitle {
            color: #07C7FA;
            font-family: 'Proximate-Regular';
            font-size: 1.2vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 1.8px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 30px;
            }

            @media screen and (max-width: 667px) {
                font-size: 22px;
            }

            @media screen and (max-width: 300px) {
                font-size: 16px;
            }
        }
    }


    #strategicApproach {
        display: flex;
        align-items: center;
        // padding: 15vh 0;

        .approachScroll {
            width: 400%;
            height: 100%;
            display: flex;
            flex-wrap: nowrap;
        }

        .gsapDeco {
            position: absolute;
            left: 0;
            top: 40%;
            z-index: -1;
            width: 2.5%;
        }

        .panel {
            width: 100vw;
        }

        .approachBox {
            border-radius: 15px;
            background: linear-gradient(180deg, rgba(3, 43, 77, 0.17) 0%, #032B4D 100%);
        }

        .advantagesBoxTitle {
            color: #FFF;
            font-family: 'Proximate-Regular';
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;

            @media screen and (max-width: 991px) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 16px;
            }
        }

        .approachImgSize {
            width: 50%;
            aspect-ratio: 3/2;
            object-fit: contain;
        }
    }

    #riskManagement {
        padding-top: 30vh;
        background-image: url(../img/ourTeam/riskManagementBg.png);
        background-size: 100% 100%;
        min-height: 150vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }

        .riskManagementSmallTitle {
            color: #07C7FA;
            font-family: 'Proximate-Regular';
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 1.8px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 16px;
            }
        }

        .riskManagementSliderTitle {
            color: #fff;
            font-family: 'Proximate-Regular';
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 1.8px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 16px;
            }
        }

        .riskManagementSectionBg {
            background-image: url(../img/ourTeam/riskManagementSectionBg.png);
            background-size: 100% 100%;
            padding: 0 15vh;
            min-height: 70vh;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width:667px) {
                background-size: cover;
                background-position: center;
            }

            @media screen and (max-width: 991px) {
                padding: 12rem;
            }

            @media screen and (max-width: 667px) {
                padding: 3rem;
            }
        }

        .riskManagementSliderBg {
            background-image: url(../img/ourTeam/riskManagementSliderBg.png);
            background-size: 100% 100%;
            padding: 0 15vh;
            min-height: 70vh;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: end;

            @media screen and (max-width:667px) {
                background-size: cover;
                background-position: center;
            }

            .riskManagementSliderLine {
                width: 10%;
                border: 1px solid white;
                margin-left: 0;
            }

            @media screen and (max-width: 991px) {
                padding: 12rem 6rem;
            }

            @media screen and (max-width: 667px) {
                padding: 3rem 2rem;
                min-height: 90vh;
            }

            .slick-next:before {
                display: none;
            }

            .slick-prev:before {
                display: none;
            }

            .slick-prev,
            .slick-next {
                width: 3.5vw;

                @media screen and (max-width:991px) {
                    width: 60px;
                }

                @media screen and (max-width:667px) {
                    width: 50px;
                }
            }

            .slick-next {
                position: absolute;
                bottom: 0;
            }

            .slick-prev {
                position: absolute;
                right: 15%;
                left: auto;

            }

            .nextArrow {
                margin-right: 15%;
                position: absolute;
                bottom: -20vh;
                z-index: 1;
                width: 100%;

                @media screen and (max-width:991px)  and (orientation:portrait) {
                    margin-right: -15px;
                    width: 100px;
                    bottom: -20vh;
                }

                @media screen and (max-width:991px)  and (orientation:landscape) {
                    margin-left: -15px;
                    width: 100px;
                    bottom: -20vw;
                }

                @media screen and (max-width:667px) {
                    margin-right: 0;
                    width: 80px;
                    right: -4%;
                    bottom: -30vh;
                }

                @media screen and (max-width:300px) {
                    margin-right: 0;
                    width: 50px;
                    right: -4%;
                    bottom: -30vh;
                }
            }

            .prevArrow {
                margin-left: 15%;
                position: absolute;
                bottom: -20vh;
                z-index: 1;
                width: 100%;

                @media screen and (max-width:991px)  and (orientation:portrait) {
                    margin-left: 10px;
                    width: 100px;
                    bottom: -20vh;
                }

                @media screen and (max-width:991px)  and (orientation:landscape) {
                    margin-left: -15px;
                    width: 100px;
                    bottom: -20vw;
                }

                @media screen and (max-width:667px) {
                    margin-left: 0;
                    width: 80px;
                    left: -4%;
                    bottom: -30vh;
                }

                @media screen and (max-width:300px) {
                    margin-right: 0;
                    width: 50px;
                    left: -4%;
                    bottom: -30vh;
                }
            }

            .progressBarSection {
                position: absolute;
                // bottom: -20vh;
                z-index: 1;
                bottom: 20%;
                left: 15vh;
                width: 45%;

                @media screen and (max-width: 991px) {
                    left: 6rem;
                }
    
                @media screen and (max-width: 667px) {
                    width: 90%;
                    left: 0;
                    right: 0;
                    margin: auto;
                    bottom:8%
                }
            }
        }

        .marqueeSectionBg {
            background-image: url(../img/ourTeam/marqueeBg.png);
            background-size: cover;
            background-position: bottom;
            min-height: 100vh;
            display: flex;
            justify-content: center;
            align-self: center;
        }

        .marqueeHugeText {
            color: #E9AE49;
            text-align: center;
            font-family: 'Proximate-Regular';
            font-size: 10vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 10px;
            text-transform: uppercase;
            opacity: 0.22;

            @media screen and (max-width: 991px) {
                font-size: 120px;
            }

            @media screen and (max-width: 667px) {
                font-size: 80px;
            }
        }

    }

}