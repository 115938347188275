.ourPartner {
    background-color: #031137;

    #ourPartnerHeader {
        background-image: url(../img/aboutUs/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    #oversight {
        min-height: 100vh;
        padding: 25vh 0 10vh 0;

        @media screen and (max-width: 991px) {
            padding: 10vh 0;
        }

        .sectionSmallTitle {
            color: #08D1FF;
            text-align: center;
            font-family: 'Proximate-Regular';
            font-size: 2vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 3.6px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 30px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 15px;
            }
        }

        .oversightDiv {
            border-radius: 15px;
            padding: 15vh 2vh 5vh 2vh;
            background: linear-gradient(180deg, #01173C -32.38%, #04335B 100%);
            margin: 15vh 0 30px 0;
            min-height: 50vh;

            @media screen and (max-width: 991px) and (orientation:landscape) {
                padding: 80vh 2vh 5vh 2vh;
            }
        }

        .oversightImgPos {
            position: absolute;
            top: 0%;
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;

            img {
                width: 55vh;
                aspect-ratio: 2/1;
                object-fit: contain;

                @media screen and (max-width: 991px) {
                    padding-top: 10%;
                    width: 100%;
                }
            }
        }

        .oversightTitle {
            color: #08D1FF;
            text-align: center;
            font-family: 'Proximate-Regular';
            font-size: 1.2vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 3.6px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 30px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 20px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 18px;
            }
        }
    }

    #certificate {
        background-image: url(../../assets/img/our-partner/certificateBg.png);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 120vh;
        padding: 10vh 0 20vh 0;

        @media screen and (max-width: 991px) {
            min-height: 100vh;
            background-size: 100% 100%;
        }

        @media screen and (max-width: 667px) {
            min-height: 100vh;
            background-size: cover;
            padding: 0 0 10vh 0;
        }

        .slick-list {
            padding-bottom: 10% !important;
        }

        .slick-next {
            right: -2%;
        }

        .slick-prev {
            left: -2%;
        }

        .slick-next:before {
            content: url(../img/home/arrowNext.png);
        }

        .slick-prev:before {
            content: url(../img/home/arrowPrev.png);
        }

        .slick-slide {
            transform: scale(0.85);
            transition: 1s;
            filter: blur(2px);
            opacity: 0.3;
            position: relative;
            z-index: 1;

            @media screen and (max-width:991px) {
                transform: scale(0.8);
            }

            @media screen and (max-width:667px) {
                transform: scale(0.8);
            }

            .view-btn {
                display: none;
            }
        }

        .slick-current {
            transform: scale(1);
            transform-origin: bottom;
            transition: 1s;
            margin: auto;
            opacity: 1;
            filter: blur(0);
            z-index: 10;

            img {
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                transition: 1s;
                // width: 100%;

                @media screen and (min-width: 992px) {
                    transform: scale(1.3);
                    position: absolute;
                }

                @media screen and (min-width: 3000px) {
                    transform: scale(2);
                    position: absolute;
                }
            }

            @media screen and (max-width:991px) {
                transform: scale(1);
            }
        }

        @media screen and (min-width:992px) {
            .slick-list {
                padding: 15vh 0 !important;
            }
        }

        .certificateShadow {
            position: absolute;
            bottom: -10%;
            text-align: center;
            left: 0;
            right: 0;
            margin: auto;
        }

    }

}