.ourCareer {
    background-color: #031137;

    .sectionSmallTitle {
        color: #08D1FF;
        text-align: center;
        font-family: 'Proximate-Regular';
        font-size: 2vw;
        font-style: normal;
        font-weight: 400;
        line-height: 110.445%;
        letter-spacing: 3.6px;
        text-transform: uppercase;

        @media screen and (max-width: 991px) and (orientation:portrait) {
            font-size: 50px;
        }

        @media screen and (max-width: 991px) and (orientation:landscape) {
            font-size: 35px;
        }

        @media screen and (max-width: 667px) {
            font-size: 35px;
        }

        @media screen and (max-width: 300px) {
            font-size: 25px;
        }
    }

    .contactUsBtn {
        border-radius: 10px;
        background: var(--Linear, linear-gradient(107deg, #8E5639 4.17%, #765B4C 50.16%, #A59573 101.19%));
        width: fit-content;
        padding: 0.8vw 2vw;
        margin: 4vh 0;

        @media screen and (max-width: 991px) {
            padding: 1rem 4rem;
        }

        span {
            color: #FFF;
            font-family: 'IstokWeb-Regular';
            font-size: 0.9vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            letter-spacing: 1.5px;

            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 15px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 13px;
            }

            @media screen and (max-width: 667px) {
                font-size: 13px;
            }

            @media screen and (max-width: 300px) {
                font-size: 12px;
            }
        }
    }


    #ourCareerHeader {
        background-image: url(../img/ourCareer/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    #ourCareerIntro {
        min-height: 80vh;

        .careerIntroBg {
            position: absolute;
            z-index: 0;
        }

        .ourCareerIntroContent {
            position: sticky;
            z-index: 1;
        }
    }


    #ourCareerAdvise {
        padding: 20vh 0 15vh 0;
        background-image: url(../img/ourCareer/careerAdviseBg.png);
        background-size: 100% 100%;
        min-height: 200vh;

        @media screen and (max-width: 991px) {
            padding-top: 0vh;
            background-size: cover;
            background-position: center;
        }

        .goldBoldText {
            color: #AD8861;
            text-align: center;
            font-family: 'IstokWeb-Bold';
            font-size: 1.1vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 25px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 20px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 18px;
            }
        }

        .blueText {
            color: #08D1FF;
            text-align: center;
            font-family: 'IstokWeb-Regular';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: underline;

            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 20px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 16px;
            }

            @media screen and (max-width: 667px) {
                font-size: 16px;
            }

            @media screen and (max-width: 300px) {
                font-size: 14px;
            }
        }

        #affiliateProgram {
            margin: 10vh 0;
            padding-top: 15vh;
            background-image: url(../img/ourCareer/affiliateProgramBg.png);
            background-size: 100% 100%;
            min-height: 80vh;

            @media screen and (max-width: 991px) {
                min-height: auto;
                padding: 15vh 0;
            }

            .marqueeHugeText {
                color: #E9AE49;
                text-align: center;
                font-family: 'Proximate-Regular';
                font-size: 10vw;
                font-style: normal;
                font-weight: 400;
                line-height: 110.445%;
                letter-spacing: 10px;
                text-transform: uppercase;
                opacity: 0.22;

                @media screen and (max-width: 991px) {
                    font-size: 120px;
                }

                @media screen and (max-width: 667px) {
                    font-size: 80px;
                }
            }
        }

        #affiliateProgramContent {
            margin-top: -35vh;

            @media screen and (max-width: 991px) {
                margin-top: 0vh;
            }

            .affiliateProgramBox {
                border-radius: 30px;
                background: linear-gradient(180deg, #01173C 0%, #032B4D 100%);
                padding: 5vh 20vh;

                @media screen and (max-width: 991px) {
                    padding: 1rem 2rem;
                }
            }

            .affiliateProgramTitle {
                color: #07C7FA;
                font-family: 'Proximate-Regular';
                font-size: 1.2vw;
                font-style: normal;
                font-weight: 400;
                line-height: 110.445%;
                letter-spacing: 1.8px;
                text-transform: uppercase;

                @media screen and (max-width: 991px) and (orientation:portrait) {
                    font-size: 25px;
                }

                @media screen and (max-width: 991px) and (orientation:landscape) {
                    font-size: 20px;
                }

                @media screen and (max-width: 667px) {
                    font-size: 20px;
                }

                @media screen and (max-width: 300px) {
                    font-size: 15px;
                }
            }

            .affiliateProgramBottom {
                padding: 5vh 20vh;

                @media screen and (max-width: 991px) {
                    padding: 1rem 2rem;
                }
            }

            .blueText2 {
                color: #08D1FF;
                text-align: center;
                font-family: 'IstokWeb-Regular';
                font-size: 1vw;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @media screen and (max-width: 991px) and (orientation:portrait) {
                    font-size: 20px;
                }

                @media screen and (max-width: 991px) and (orientation:landscape) {
                    font-size: 16px;
                }

                @media screen and (max-width: 667px) {
                    font-size: 16px;
                }

                @media screen and (max-width: 300px) {
                    font-size: 14px;
                }
            }
        }
    }

    #newsEvents {
        padding: 6vh 0 20vh 0;

        .newsDiv {
            border-radius: 15px;
            background: linear-gradient(0deg, #050E20 0%, #050E20 100%), linear-gradient(180deg, #01173C 0%, #032B4D 100%);

            img {
                border-radius: 15px 15px 0 0;
                width: 100%;
            }

            .newsContentDiv {
                .dateText {
                    color: #B6B6B6;
                    font-family: 'IstokWeb-Regular';
                    font-size: 0.8vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media screen and (max-width: 991px) and (orientation:portrait) {
                        font-size: 20px;
                    }

                    @media screen and (max-width: 991px) and (orientation:landscape) {
                        font-size: 16px;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 16px;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 14px;
                    }
                }

                .newsTitleText {
                    color: #FFF;
                    font-family: 'Proximate-Regular';
                    font-size: 1.1vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 1.5px;

                    @media screen and (max-width: 991px) and (orientation:portrait) {
                        font-size: 25px;
                    }

                    @media screen and (max-width: 991px) and (orientation:landscape) {
                        font-size: 20px;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 20px;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 15px;
                    }
                }

                .newsDescText {
                    color: #FFF;
                    text-align: center;
                    font-family: 'IstokWeb-Regular';
                    font-size: 0.8vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    @media screen and (max-width: 991px) and (orientation:portrait) {
                        font-size: 20px;
                    }

                    @media screen and (max-width: 991px) and (orientation:landscape) {
                        font-size: 16px;
                    }

                    @media screen and (max-width: 667px) {
                        font-size: 16px;
                    }

                    @media screen and (max-width: 300px) {
                        font-size: 14px;
                    }
                }

            }


        }
    }

}