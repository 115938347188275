.homepage {
    background-color: #031137;

    .learnMoreBtn {
        border-radius: 10px;
        background: var(--Linear, linear-gradient(107deg, #8E5639 4.17%, #765B4C 50.16%, #A59573 101.19%));
        width: fit-content;
        padding: 0.8vw 2vw;

        @media screen and (max-width: 991px) {
            padding: 1rem 2rem;
        }

        @media screen and (max-width: 300px) {
            padding: 1rem 1rem;
        }

        span {
            color: #FFF;
            font-family: 'IstokWeb-Regular';
            font-size: 0.9vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 15px;
            }

            @media screen and (max-width: 300px) {
                font-size: 13px;
            }
        }
    }

    #homeHeader {
        background-image: url(../img/home/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
            min-height: 80vh;
        }

        .homeHeaderSection {
            min-height: 100vh;
            @media screen and (max-width: 991px) {
                min-height: 80vh;
            }
        }
    }

    #marqueeHeader {
        background: #050E20;
        padding: 2vh 0;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow-x: hidden;

        @media screen and (max-width: 991px) {
            position: relative;
            bottom: 0;
            left: 0;
        }

        .marqueeNameText {
            color: #6FA7FB;
            font-family: 'Proximate-Regular';
            font-size: 1vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 0.72px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 12px;
            }
        }

        .marqueeAmountText {
            color: #FFF;
            font-family: 'IstokWeb-Regular';
            font-size: 1.2vw;
            font-style: normal;
            font-weight: 700;
            line-height: 110.445%;
            /* 22.089px */
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 20px;
            }
        }

        .marqueeRateText {
            color: #00A711;
            font-family: 'IstokWeb-Regular';
            font-size: 0.8vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 12px;
            }
        }

        .marqueeLogo {
            width: 2.5vw;
            margin-right: 1.2vw;

            @media screen and (max-width: 991px) {
                width: 40px;
                margin-right: 1rem;
            }
        }

        .sideBorderWhite {
            border-right: 1px solid #122B5B;
        }

        .tradingview-widget-copyright {
            display: none;
        }

        div div{
            transform: scale(calc(100%/0.8));

            @media screen and (min-width: 3000px) {
                transform: scale(calc(100%/0.5));
            }
        }
    }

    #investHappiness {
        padding: 35vh 0;

        @media screen and (max-width: 991px) {
            padding: 30vh 0;
        }

        .investHappinessContent {
            position: sticky;
            z-index: 2;
        }

        .happyInvestBg {
            width: 50%;
            position: absolute;
            top: 30%;
            z-index: 1;

            @media screen and (max-width: 991px) {
                width: 100%;
            }
        }

        .happyInvestParallax {
            width: 70%;
            height: 100%;
            position: absolute;
            top: 0%;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 0;

            @media screen and (max-width: 991px) {
                width: 100%;
            }
        }
    }

    #happinessDetailSection {
        background-image: url(../../assets/img/home/happinessDetailsBg.png);
        background-size: 100% 100%;
        padding-bottom: 20vh;

        @media screen and (max-width: 991px) {
            padding-bottom: 0vh;
            background-size: cover;
            background-position: center;
        }

        @media screen and (min-width: 992px) {
            .happinessDetailSection {
                padding-left: 2vw;
            }
        }

        .happinessDetailTitle {
            color: #07C7FA;
            font-family: 'Proximate-Regular';
            font-size: 2vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 2.4px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 40px;
            }

            @media screen and (max-width: 667px) {
                font-size: 30px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }
    }

    #ourClients {
        background-image: url(../../assets/img/home/ourClientBg2.png);
        background-size: 100% 200%;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 130vh;

        @media screen and (max-width: 991px) {
            min-height: 100vh;
            background-size: 100% 150%;
        }

        @media screen and (max-width: 667px) {
            min-height: 100vh;
            background-size: 100% 100%;
        }

        .clientProfilePic {
            width: 25%;

            @media screen and (max-width: 991px) {
                width: 25%;
            }

            @media screen and (max-width: 667px) {
                width: 60%;
            }
        }

        .clientName {
            color: #08D1FF;
            text-align: center;
            font-family: 'IstokWeb-Regular';
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 30px;
            }

            @media screen and (max-width: 667px) {
                font-size: 25px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }

        .clientFeedbackBg1 {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            display: none;

            @media screen and (max-width: 667px) {
                width: 20%;
            }
        }

        .clientFeedbackBg2 {
            position: absolute;
            bottom: 0%;
            right: 0;
            z-index: -1;
            display: none;

            @media screen and (max-width: 667px) {
                width: 20%;
            }
        }

        .slick-list {
            padding-bottom: 10% !important;
        }

        .slick-next {
            right: 10px;
        }

        .slick-prev {
            left: 10px;
        }

        .slick-next:before {
            content: url(../img/home/arrowNext.png);
        }

        .slick-prev:before {
            content: url(../img/home/arrowPrev.png);
        }

        .slick-slide {
            transform: scale(0.5);
            transition: 1s;
            filter: blur(10px);

            @media screen and (max-width:991px) {
                transform: scale(0.8);
            }

            @media screen and (max-width:667px) {
                transform: scale(0.8);
            }

            .view-btn {
                display: none;
            }
        }

        .slick-current {
            transform: scale(1) translateY(30%);
            transform-origin: bottom;
            transition: 1s;
            margin: auto;
            filter: blur(0);

            .clientFeedbackBg1 {
                display: block;
            }

            .clientFeedbackBg2 {
                display: block;
            }

            @media screen and (max-width:991px) {
                transform: scale(1);
            }
        }

        .slick-dots li button:before {
            font-size: 0.6vw;
            opacity: 1 !important;

            @media screen and (max-width:991px) {
                font-size: 10px;
            }
        }
        
        .slick-dots li {
            margin: 0 0.5vw;

            @media screen and (max-width:991px) {
                margin: 0 5px;
            }
        }

        .slick-dots .slick-active button:before {
            color: #08D1FF !important;
        }

        .slick-dots li button {
        }
    }

    #subscribeNewsletter {
        .form-control {
            border: none;
            border-bottom: 1px solid #FFF;
            border-radius: 0;
            background: transparent !important;
            color: #fff;
            font-size: 1vw;

            @media screen and (max-width: 991px) {
                font-size: 18px;
            }

            @media screen and (max-width: 667px) {
                font-size: 16px;
            }

            @media screen and (max-width: 300px) {
                font-size: 15px;
            }
        }

        .newsletterBtn {
            border-radius: 10px;
            background: var(--Linear, linear-gradient(107deg, #8E5639 4.17%, #765B4C 50.16%, #A59573 101.19%));

            .newsletterBtnText {
                color: #fff;
                font-size: 1.3vw;

                @media screen and (max-width: 991px) {
                    font-size: 25px;
                }

                @media screen and (max-width: 667px) {
                    font-size: 20px;
                }

                @media screen and (max-width: 300px) {
                    font-size: 15px;
                }
            }
        }

        .smallText {
            color: #FFF;
            text-align: center;
            font-family: 'IstokWeb-Regular';
            font-size: 0.8vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 0.63;

            @media screen and (max-width: 991px) {
                font-size: 16px;
            }

            @media screen and (max-width: 667px) {
                font-size: 15px;
            }

            @media screen and (max-width: 300px) {
                font-size: 13px;
            }
        }

        .newletterImgBg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            z-index: 0;
        }

        .newsletterSideImgPos {
            position: absolute;
            right: 0;
            top: -10%;
        }
    }

    #strategicPartner {
        background-image: url(../../assets/img/home/strategicBg.png);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 120vh;
        display: flex;
        align-items: center;
        margin-top: -15vh;

        @media screen and (max-width: 991px) {
            padding-bottom: 20vh;
            background-size: cover;
            background-position: top;
        }

        .strategicImg {
            width: 40%;

            @media screen and (max-width: 667px) {
                width: 50%;
            }
        }

        .strategicPartnerRow {
            padding-top: 30vh;
        }

        .strategicTextPos {
            position: absolute;
            bottom: 10%;

            span {
                color: #FFF;
                font-family: 'Proximate-Regular';
                font-size: 1.3vw;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;

                @media screen and (max-width: 991px) {
                    font-size: 25px;
                }

                @media screen and (max-width: 667px) {
                    font-size: 20px;
                }

                @media screen and (max-width: 300px) {
                    font-size: 16px;
                }
            }
        }
    }
}