.ourStrategies {
    background-color: #031137;

    .learnMoreBtn {
        border-radius: 10px;
        background: var(--Linear, linear-gradient(107deg, #8E5639 4.17%, #765B4C 50.16%, #A59573 101.19%));
        width: fit-content;
        padding: 0.8vw 2vw;

        @media screen and (max-width: 991px) {
            padding: 1rem 2rem;
        }

        span {
            color: #FFF;
            font-family: 'IstokWeb-Regular';
            font-size: 0.9vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 15px;
            }
        }
    }

    #ourStrategiesHeader {
        background-image: url(../img/our-strategies/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }

        .headerDesc {
            color: #FFF;
            text-align: center;
            font-family: 'IstokWeb-Regular';
            font-size: 1.2vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
    
            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 30px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 18px;
            }
    
            @media screen and (max-width: 667px) {
                font-size: 20px;
            }
    
            @media screen and (max-width: 300px) {
                font-size: 18px;
            }
        }

        .overlayLayer {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background-color: #0311377c;
        }

        .ourStrategiesHeaderContent {
            position: sticky;
            z-index: 1;
        }
    }

    #strategiesType {
        min-height: 100vh;
        padding: 15vh 0 25vh 0;

        .strategyDiv {
            border-radius: 15px;
            padding: 6vh 1.5vw 0 1.5vw;
            min-height: 40vh;
            background: linear-gradient(180deg, #01173C -32.38%, #04335B 100%);

            span {
                position: sticky;
                z-index: 10;
            }

            @media screen and (max-width: 991px) and (orientation:portrait) {
                padding: 6vh 30px 0 30px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                padding: 15vw 30px 15vw 30px;
            }

            @media screen and (max-width: 667px) {
                padding: 5vh 20px 0 20px;
            }

            @media screen and (max-width: 300px) {
                padding: 5vh  10px 0 10px;
            }
        }

        .strategyImgPos {
            position: absolute;
            bottom: 0%;
            right: 0;
            text-align: center;
            z-index: 0;
            text-align: right;

            img {
                width: 15vw;
                object-fit: contain;
                
                @media screen and (max-width: 991px) {
                    padding-top: 10%;
                    width: 40%;
                }
            }
        }

        .strategyTitle {
            color: #08D1FF;
            text-align: center;
            font-family: 'Proximate-Regular';
            font-size: 1.2vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 3.6px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 30px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 20px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 18px;
            }
        }
    }

}