.goldWave {
    background-color: #031137;

    .learnMoreBtn {
        border-radius: 10px;
        background: var(--Linear, linear-gradient(107deg, #8E5639 4.17%, #765B4C 50.16%, #A59573 101.19%));
        width: fit-content;
        padding: 0.8vw 2vw;

        @media screen and (max-width: 991px) {
            padding: 1rem 2rem;
        }

        span {
            color: #FFF;
            font-family: 'IstokWeb-Regular';
            font-size: 0.9vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 15px;
            }
        }
    }


    #goldWaveHeader {
        background-image: url(../img/goldWave/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    #advantages {
        min-height: 100vh;
        padding: 10vh 0;

        .advantageDeco {
            position: absolute;
            top: -20%;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 0;
            width: 100%;

            @media screen and (max-width: 991px) {
                top: 0%;
            }
        }

        div {
            position: sticky;
            z-index: 1;
        }
    }

    #impactSection {
        background-image: url(../../assets/img/home/happinessDetailsBg.png);
        background-size: 100% 100%;
        padding: 10vh 0;

        @media screen and (max-width: 991px) {
            padding: 0vh;
            background-size: cover;
        }

        .impactSectionContent {
            @media screen and (min-width: 992px) {
                padding: 0 10vh;
            }
        }
    }

    #goldWaveStrategy {
        background-image: url(../../assets/img/home/ourClientBg2.png);
        background-size: 100% 200%;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 991px) {
            min-height: 100vh;
            background-size: 100% 150%;
            padding: 10vh 0;
        }

        @media screen and (max-width: 667px) {
            min-height: 100vh;
            background-size: 100% 100%;
        }

        .sectionSmallTitle {
            color: #08D1FF;
            text-align: center;
            font-family: 'Proximate-Regular';
            font-size: 2vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 3.6px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 50px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 35px;
            }

            @media screen and (max-width: 667px) {
                font-size: 35px;
            }

            @media screen and (max-width: 300px) {
                font-size: 25px;
            }
        }

        .goldWaveStrategyTitle {
            color: #FFF;
            font-family: 'IstokWeb-Regular';
            font-size: 1.2vw;
            font-style: normal;
            font-weight: 700;
            line-height: 122.445%;

            @media screen and (max-width: 991px) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 16px;
            }
        }
    }

    #advantagesDetails {

        .advantageScroll {
            width: 500%;
            height: 100%;
            display: flex;
            flex-wrap: nowrap;

            .advantageDetailsTitlePos {
                padding-bottom: 33vh;
            }
        }

        .panel {
            width: 100vw;
        }

        .advantagesBox {
            border-radius: 15px;
            background: linear-gradient(180deg, #01173C -32.38%, #04335B 100%);
            min-height: 50vh;

            @media screen and (max-width: 991px) {
                min-height: auto;
            }
        }

        .advantagesBoxTitle {
            color: #FFF;
            font-family: 'Proximate-Regular';
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;

            @media screen and (max-width: 991px) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 16px;
            }
        }

        .advantageImgSize {
            width: 80%;
            aspect-ratio: 3/2;
            object-fit: contain;
        }

        .gsapDeco {
            position: absolute;
            left: 0;
            top: 20%;
            z-index: -1;
            width: 7%;
        }
    }

    #goldWaveIntro {
        background-image: url(../../assets/img/goldWave/introBg.png);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 90vh;
        width: 100%;
        padding-top: 20vh;

        .goldWaveIntroDiv {
            border-radius: 15px;
            background: radial-gradient(61.4% 115% at 50% -65%, #0053B7 0%, rgba(0, 83, 183, 0.00) 100%), #050E20;
        }

        .advantagesBoxTitle {
            color: #FFF;
            font-family: 'Proximate-Regular';
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;

            @media screen and (max-width: 991px) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 16px;
            }
        }

        .submitBtn {
            border-radius: 10px;
            background: var(--Linear, linear-gradient(107deg, #8E5639 4.17%, #765B4C 50.16%, #A59573 101.19%));
            width: fit-content;
            padding: 0.8vw 2vw;

            @media screen and (max-width: 991px) {
                padding: 1rem 2rem;
            }

            span {
                color: #FFF;
                font-family: 'IstokWeb-Regular';
                font-size: 0.9vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;

                @media screen and (max-width: 991px) {
                    font-size: 15px;
                }
            }
        }

    }
}