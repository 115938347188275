.tradingView {
    background-color: #031137;

    #tradingViewHeader {
        background-image: url(../img/trading-view/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    #tradingPlatform {
        padding-bottom: 20vh;

        @media screen and (max-width: 991px) {
            padding-bottom: 20vh;
        }

        div {
            position: sticky;
            z-index: 1;
        }

        .tradingPlatformDeco {
            position: absolute;
            right: 0;
            top: 25%;
            z-index: 0;
        }
    }
}