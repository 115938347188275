.marketDiversity {
    background-color: #031137;

    #marketDivHeader {
        background-image: url(../img/market-diversity/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    #marketType {
        min-height: 100vh;
        padding: 15vh 0 30vh 0;

        .sectionSmallTitle {
            color: #08D1FF;
            text-align: center;
            font-family: 'Proximate-Regular';
            font-size: 2vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 3.6px;
            text-transform: uppercase;
    
            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 50px;
            }
    
            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 35px;
            }
    
            @media screen and (max-width: 667px) {
                font-size: 35px;
            }
    
            @media screen and (max-width: 300px) {
                font-size: 25px;
            }
        }

        .marketTypeDiv {
            border-radius: 15px;
            padding: 10vh 30px;
            background: linear-gradient(180deg, #01173C -32.38%, #04335B 100%);
            min-height: 60vh;
            
            img {
                width: 100%;
                aspect-ratio: 2/1;
                object-fit: contain;
            }
        }

        .marketTypeTitle {
            color: #08D1FF;
            text-align: center;
            font-family: 'Proximate-Regular';
            font-size: 1.2vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 3.6px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) {
                font-size: 30px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 18px;
            }
        }
    }
}