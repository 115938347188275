.aboutUs {
    background-color: #031137;

    #aboutUsHeader {
        background-image: url(../img/our-partner/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    #aboutUsIntro {
        background-image: url(../../assets/img/aboutUs/aboutUsIntroBg.png);
        margin: 20vh 0;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;

        @media screen and (max-width: 991px) {
            margin: 10vh 0;
            min-height: 100vh;
            background-size: cover;
        }

        @media screen and (max-width: 667px) {
            min-height: 100vh;
            background-size: cover;
        }

        .aboutUsIntroContent {
            margin-top: 5rem
        }

    }


    #methodologies {
        padding-bottom: 10vh;

        .methodologiesTitle {
            color: #07C7FA;
            font-family: 'Proximate-Regular';
            font-size: 2vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 2.4px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 40px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 30px;
            }

            @media screen and (max-width: 667px) {
                font-size: 30px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }

        .methodContentPos {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 6%;
        }
    }

    #dataStats {
        border-radius: 60px;
        background: #050E20;

        .CircularProgressbar-path {
            stroke: red;
        }

        .CircularProgressbar-trail {
            stroke: gray;
        }

        .CircularProgressbar-text {
            fill: yellow;
        }

        .CircularProgressbar-background {
            fill: green;
        }
    }

    #ourMission {
        padding: 20vh 0;

        @media screen and (max-width: 991px) {
            padding: 10vh 0;
        }

        .missionIndexText {
            color: #07C7FA;
            font-family: 'IstokWeb-Regular';
            font-size: 2vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 2.4px;
            font-style: italic;

            @media screen and (max-width: 991px) {
                font-size: 40px;
            }

            @media screen and (max-width: 667px) {
                font-size: 30px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }
    }

    #ourValue {
        border-radius: 0px 350px;
        background: linear-gradient(180deg, #032B4D 0%, rgba(3, 43, 77, 0.00) 23.7%, rgba(3, 43, 77, 0.00) 76.75%, #032B4D 100%);
        padding: 10vh 0;

        @media screen and (max-width: 991px) {
            border-radius: 0px 200px;
        }

        @media screen and (max-width: 667px) {
            border-radius: 0px 100px;
        }

        .valueContentTitle {
            color: #07C7FA;
            font-family: 'Proximate-Regular';
            font-size: 2vw;
            font-style: normal;
            font-weight: 400;
            line-height: 110.445%;
            letter-spacing: 2.4px;
            text-transform: uppercase;

            @media screen and (max-width: 991px) and (orientation:portrait) {
                font-size: 35px;
            }

            @media screen and (max-width: 991px) and (orientation:landscape) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 25px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }

        .valueContentPos1 {
            margin-left: -10vw;

            @media screen and (max-width: 991px) {
                margin-left: 0;
            }
        }

        .valueContentPos2 {
            margin-right: -10vw;

            @media screen and (max-width: 991px) {
                margin-right: 0;
            }
        }
    }

    #whyUs {
        min-height: 100vh;
        display: flex;
        align-items: center;

        @media screen and (max-width: 991px) {
            padding: 10vh 0 25vh 0;
        }

        .whyUsTitle {
            color: #FFF;
            font-family: 'Proximate-Regular';
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;

            @media screen and (max-width: 991px) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 20px;
            }

            @media screen and (max-width: 300px) {
                font-size: 16px;
            }
        }

        .whyUsDecoPos {
            position: absolute;
            left: 0;
            top: -40%;
            width: 25%;
        }

        .accordion__button {
            background-color: transparent !important;
            padding-top: 2vh;
        }

        .accordionSectionTitle {
            color: #FFF;
            font-family: 'Microsoft YaHei';
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-bottom: 3vh;

            @media screen and (max-width: 992px) {
                font-size: 30px;
            }

            @media screen and (max-width: 667px) {
                font-size: 25px;
            }

            @media screen and (max-width: 300px) {
                font-size: 20px;
            }
        }

        .accordionPosition {
            padding-top: 3vh;
            padding-bottom: 10vh;

            @media screen and (max-width: 992px) {
                padding-top: 2rem;
                padding-bottom: 3rem;
            }
        }

        .accordionTitle {
            font-family: 'Urbanist-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 1.2vw;
            line-height: 29px;
            color: #fff;

            @media (max-width: 991px) {
                font-weight: 700;
                font-size: 20px;
                line-height: 29px;
            }

            @media (max-width: 667px) {
                font-weight: 500;
                font-size: 16px;
                line-height: 29px;
            }

            @media screen and (max-width: 300px) {
                font-weight: 500;
                font-size: 15px;
                line-height: 29px;
            }
        }

        .accordionTitle-inactive {
            font-family: 'Urbanist-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 1vw;
            line-height: 29px;
            color: #fff;

            @media (max-width: 991px) {
                font-weight: 700;
                font-size: 16px;
                line-height: 29px;
            }

            @media (max-width: 667px) {
                font-weight: 500;
                font-size: 16px;
            }

            @media screen and (max-width: 300px) {
                font-size: 15px;
            }
        }

        .accordionContent {
            font-family: 'Urbanist-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 1vw;
            line-height: 2vw;
            text-align: left;
            color: #C1E1F2;

            @media (max-width: 992px) {
                font-size: 14px;
                line-height: 25px;
            }

            @media (max-width: 677px) {
                font-size: 12px;
                line-height: 22px;
            }

            @media screen and (max-width: 300px) {
                font-size: 11px;
                line-height: 15px;
            }
        }


        .accordion__button {
            background: transparent
        }

        .accordion__panel {
            padding-left: 8px;
            padding-top: 4px !important;
        }


        .accordion__button:before {
            display: none;
        }

        .lh-1 {
            line-height: 1;
        }

        .accordion__button::before {
            display: none !important;
        }

        .accordionIcon {
            font-size: 1vw;

            @media (max-width: 991px) {
                font-size: 18px;
            }
        }

        .accordionItemPanelPoint {
            li {
                text-align: justify;
                margin-bottom: 1vh;
            }
            text-align: justify;
        }

        .selectedExpandIcon {
            float: right;
            color: #E9AE49;
        }

        .nonselectedExpandIcon {
            float: right;
            color: #E9AE49;
        }

        .borderBottomWhite {
            border-bottom: 1px solid #0053B7;
            padding-bottom: 1vh;
        }

        .borderTopWhite {
            padding-top: 1vh;
            border-top: 1px solid #0053B7;
        }

    }
}