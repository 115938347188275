.resource {
    background-color: #031137;

    #resourceHeader {
        background-image: url(../img/resource/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    .resourceHeaderDesc {
        margin-top: -10vh;

        .submitBtn {
            border-radius: 10px;
            background: var(--Linear, linear-gradient(107deg, #8E5639 4.17%, #765B4C 50.16%, #A59573 101.19%));
            width: fit-content;
            padding: 0.8vw 2vw;

            @media screen and (max-width: 991px) {
                padding: 1rem 2rem;
            }

            span {
                color: #FFF;
                font-family: 'IstokWeb-Regular';
                font-size: 0.9vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;

                @media screen and (max-width: 991px) {
                    font-size: 15px;
                }
            }
        }
    }

    #resourceType {
        background-image: url(../../assets/img/resource/resourceTypeBg.png);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;
        margin-top: 10vh;
        padding: 20vh 0 0 0;


        @media screen and (max-width: 991px) {
            min-height: 100vh;
            background-size: cover;
            padding: 20vh 0;
        }

        @media screen and (max-width: 667px) {
            min-height: 100vh;
            background-size: cover;
        }

        .resourceTypeTitleMb {
            margin-bottom: 12vh;

            @media screen and (max-width: 991px) {
                margin-bottom: 5rem
            }
        }

        .resourceTypeImgBg {
            margin-top: -50vh;
            margin-bottom: -30vh;
            width: 100%;
        }

        .resourceImgSize {
            width: 10vh;
            aspect-ratio: 1/1;
            object-fit: contain;

            @media screen and (max-width: 991px) {
                width: 20%;
            }

            @media screen and (max-width: 667px) {
                width: 25%;
            }
        }

        .curveContent {
            background-color: transparent;
            height: 40vh;
            position: sticky;
            z-index: 9;
        }

        .dottedLineDiv {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: 40vh;
            z-index: 0;
            width: 100%;

            img {
                width: 80%;
                object-fit: cover;
                height: 40vh;
                object-position: top;

                @media screen and (max-width: 1500px) {
                    object-fit: fill;
                    width: 75vw;

                }

            }
        }

        .hoverDescPos {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 1;

            .hoverInnerPos {
                position: relative;
                height: 40vh;
                width: 100%;
            }

        }

        .resource1 {
            transform: translateX(-120%) translateY(100%);
            transform-origin: center center;
        }

        .resource2 {
            transform: translateX(-180%) translateY(-190%);
            transform-origin: center center;
        }

        .resource3 {
            transform: translateY(-350%);
            transform-origin: center center;
        }

        .resource4 {
            transform: translateX(180%) translateY(-190%);
            transform-origin: center center;
        }

        .resource5 {
            transform: translateX(120%) translateY(100%);
            transform-origin: center center;
        }
        
        .hoverResourceDiv {
            background-color: #fff;
            width: 35%;
            box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.40);
            border-radius: 10px;

            @media screen and (max-width: 991px) {
                width: 100%;
            }
        }

        .hoverResourceTitle {
            color: #011A3F;
            font-family: 'IstokWeb-Regular';
            font-size: 1.2vw;
            font-style: normal;
            font-weight: 700;
            line-height: 122.445%;

            @media screen and (max-width: 991px) {
                font-size: 30px;
            }

            @media screen and (max-width: 667px) {
                font-size: 22px;
            }
        }

        .hoverResourceDesc {
            color: #717171;
            font-family: 'IstokWeb-Regular';
            font-size: 0.8vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media screen and (max-width: 991px) {
                font-size: 22px;
            }

            @media screen and (max-width: 667px) {
                font-size: 16px;
            }
        }

        .hoverDesc1 {
            position: absolute;
            bottom: -26%;
            left: 14%;
            display: none;
        }

        .hoverDesc2 {
            position: absolute;
            top: 26%;
            left: 27%;
            display: none;
        }

        .hoverDesc3 {
            position: absolute;
            top: 18%;
            left: 0;
            right: 0;
            margin: auto;
            // display: none;
        }

        .hoverDesc4 {
            position: absolute;
            top: 26%;
            right: 27%;
            display: none;
        }

        .hoverDesc5 {
            position: absolute;
            bottom: -26%;
            right: 14%;
            display: none;
        }

    }
}