.howToInvest {
    background-color: #031137;

    #investHeader {
        background-image: url(../img/howToInvest/headerBg.png);
        background-size: 100% 100%;
        min-height: 100vh;

        @media screen and (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    #investRoadmap {
        padding: 20vh 0;
        min-height: 100vh;

        .stepIndex {
            background: linear-gradient(180deg, #1B3259 -32.38%, #0053B7 135.62%);
            width: fit-content;
            border-radius: 50%;
            padding: 2rem;
            transition: 1s;

            @media screen and (max-width: 667px) {
                padding: 1.2rem;
            }

            span {
                color: #08D1FF;
                text-align: center;
                font-family: 'Proximate-Regular';
                font-size: 1.3vw;
                font-style: normal;
                font-weight: 400;
                line-height: 110.445%;
                letter-spacing: 1.25px;
                text-transform: uppercase;

                @media screen and (max-width: 991px) {
                    font-size: 20px;
                }

                @media screen and (max-width: 667px) {
                    font-size: 17px;
                }
    
                @media screen and (max-width: 300px) {
                    font-size: 13px;
                }
            }
        }

        .stepTitle {
            color: #08D1FF;
            font-family: 'IstokWeb-Regular';
            font-size: 1.5vw;
            font-style: normal;
            font-weight: 700;
            line-height: 122.445%;

            @media screen and (max-width: 991px) {
                font-size: 25px;
            }

            @media screen and (max-width: 667px) {
                font-size: 22px;
            }

            @media screen and (max-width: 300px) {
                font-size: 13px;
            }
        }

        .investRoadmapMap {
            margin-top: 10vh;
            padding: 5vh;
        }

        .roadmapMargin {
            margin: 5vh 0;

            @media screen and (max-width: 667px) {
                margin: 2rem 0;
            }
        }

        .roadmapLastBox {
            border-radius: 30px;
            background: linear-gradient(180deg, #01173C 0%, #032B4D 100%);
            padding: 5vw 10vw;
            transition: 1s;

            &:hover {
                box-shadow: 0px 2px 20px 0px #0EB7FF;
            }
        }

        .roadmapLine {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: 100%;
        }

        .roadmapImgBg {
            position: absolute;
            top: -80%;
            left: 35%;
            width: 100%;
            z-index: 0;
            display: none;
            transition: 1s;
        }

        .roadmapImg {
            width: 30%;
            mix-blend-mode: luminosity;
            opacity: 0.5;
            transition: 1s;

            @media screen and (max-width: 991px) {
                width: 50%;
                mix-blend-mode: normal;
                opacity: 1;
            }

            @media screen and (max-width: 667px) {
                width: 100%;
                mix-blend-mode: normal;
                opacity: 1;
            }
        }

        .learnMoreBtn {
            border-radius: 10px;
            background: var(--Linear, linear-gradient(107deg, #8E5639 4.17%, #765B4C 50.16%, #A59573 101.19%));
            width: fit-content;
            padding: 0.8vw 2vw;
    
            @media screen and (max-width: 991px) {
                padding: 1rem 2rem;
            }
    
            span {
                color: #FFF;
                font-family: 'IstokWeb-Regular';
                font-size: 0.9vw;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
    
                @media screen and (max-width: 991px) {
                    font-size: 15px;
                }
            }
        }
    

        .timeline {
            ul {
                li {
                    list-style-type: none;
                    position: relative;
                    width: 6px;
                    margin: 0 auto;
                    padding-top: 50px;
                    background: #fff;

                    &::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transform: translateX(-50%) rotate(45deg);
                        width: 20px;
                        height: 20px;
                        z-index: 2;
                        background: #eee;
                    }

                    div {
                        position: relative;
                        bottom: 0;
                        width: 400px;
                        padding: 20px;
                        background: #fff;
                        box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2);
                        border-radius: 5px;
                        display: flex;
                        align-items: center;

                        time {
                            position: absolute;
                            background: #f5af19;
                            width: 80px;
                            height: 30px;
                            top: -15px;
                            border-radius: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            letter-spacing: 2px;
                        }

                        div {
                            height: 100px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            p {
                                text-align: center;
                            }
                        }

                        .discovery {
                            margin-right: 10px;
                        }

                        .scientist {}
                    }

                    &:nth-of-type(odd) {
                        >div {
                            left: 45px;
                        }
                    }

                    &:nth-of-type(even) {
                        >div {
                            left: -439px;
                        }
                    }
                }
            }
        }

        // ANIMATION
        .timeline {
            ul {
                li {
                    div {
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.5s ease-in-out;
                    }

                    &:nth-of-type(odd) {
                        div {
                            transform: translate3d(100px, -10px, 0) rotate(10deg);
                        }
                    }

                    &:nth-of-type(even) {
                        div {
                            transform: translate3d(-100px, -10px, 0) rotate(10deg);
                        }
                    }

                    &.in-view {
                        div {
                            transform: none;
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 900px) {
            .timeline {
                ul {
                    li {
                        div {
                            width: 250px;
                            flex-direction: column;

                            div {
                                width: 80%;
                                margin: 10px;
                            }
                        }

                        &:nth-of-type(even) {
                            >div {
                                left: -289px;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 600px) {
            body {
                background: #8bfff4;
            }

            .timeline {
                ul {
                    li {
                        margin-left: 20px;

                        div {
                            width: calc(100vw - 91px);
                        }

                        &:nth-of-type(even) {
                            >div {
                                left: 45px;
                            }
                        }
                    }
                }
            }
        }
    }
}